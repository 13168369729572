import axios from "axios";
import fr from "date-fns/locale/fr";
import React, { useState } from "react";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { X } from "react-feather";
import { toast } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import { formatDate } from "../Utils";

import _fr from "react-phone-input-2/lang/fr.json";
import "react-phone-input-2/lib/bootstrap.css";
import { useSubmitted } from "./SubmitContext";

import "react-day-picker/dist/style.css";
import { cityData } from "../data";
import { handleNotification } from "../lib/Socket";
import DateSelector from "./DateSelector";
import { formatPrice } from "./Info";

setDefaultLocale(fr);

const FormModal = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    deliveryCity: "",
    deliveryLocation: "",
    price: "",
  });

  const [deliveryDate, setDeliveryDate] = useState();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [pending, setPending] = useState(false);
  const { setSubmitted, setData } = useSubmitted();

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedCity = cityData.find((city) => city.name === value);

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      price:
        name === "deliveryCity" && selectedCity
          ? selectedCity.price
          : prevData.price,
    }));
  };

  const handleCommande = (e) => {
    e.preventDefault();
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.deliveryCity === "" ||
      formData.deliveryLocation === "" ||
      phoneNumber === ""
    ) {
      toast.error("Veuillez remplir tous les champs");
      return;
    }
    if (!deliveryDate) {
      toast.error("Veuillez sélectionner votre date de livraison");
      return;
    }

    sendCommande();
  };

  const handleSMS = ({
    phone,
    lastName,
    link,
    deliveryDate,
    price,
    templateId,
  }) => {
    const options = {
      method: "POST",
      url: `${process.env.REACT_APP_SERVER}/api/v3/notification/whatsapp`,
      headers: { "Content-Type": "application/json" },
      data: {
        phone: phone,
        lastName: `*${lastName}*`,
        link: link,
        deliveryDate: deliveryDate,
        price: price,
        templateId: templateId,
        type: "whatsapp",
      },
    };
    axios
      .request(options)
      .then(function (response) {
        console.log("whatsapp message sent");
      })
      .catch(function (error) {
        console.error("whatsapp message not sent");
      });
  };

  const handlePaiement = () => {
    const load = toast.loading("Initialisation du paiement en cours...");
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/v3/commandes/initiate`, {
        deliveryDate,
        phoneNumber,
        storedPrice: formData.price,
        type: "whatsapp",
        formData,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message, {
            id: load,
          });
          window.location.href = res.data.url;
        } else {
          toast.error(res.data.message, {
            id: load,
          });
        }
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite", {
          id: load,
        });
      });
  };

  const sendCommande = async () => {
    const msg1 = `${formData.lastName}, pour confirmer l'expedition de votre livre: Le guide complet pour la vente sur whatsapp business cliquez ici: https://bit.ly/wave225
    `;

    const msg2 = `${
      formData.lastName
    }, notre livreur vous appellera ${formatDate(
      deliveryDate
    )} pour livrer votre livre: Le guide complet pour la vente sur whatsapp business. ${
      formData.price
    } FCFA a payer a la livraison.`;

    let message = formData.deliveryCity === "Hors d'Abidjan" ? msg1 : msg2;

    setPending(true);
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/v3/commandes`,
        {
          deliveryDate,
          phoneNumber,
          type: "whatsapp",
          ...formData,
        }
      );

      if (res.data.success) {
        setData({
          deliveryDate,
          phoneNumber,
          ...formData,
          message: `${message}`,
        });
        handleSMS({
          phone: phoneNumber,
          lastName: formData.lastName,
          link: "https://bit.ly/wave225",
          deliveryDate: formatDate(deliveryDate),
          price: String(formData.price),
          templateId:
            formData.deliveryCity === "Hors d'Abidjan"
              ? "whatsapp_interieur_commande_livre"
              : "whatsapp_commande_livre",
        });
        if (formData.deliveryCity === "Hors d'Abidjan") {
          handlePaiement();
        } else {
          setSubmitted(true);
          closeModal();
        }
        handleNotification({
          msg: `${formData?.firstName} ${formData?.lastName} (${formData?.deliveryCity}) vient de passer commande`,
        });
        setFormData({
          firstName: "",
          lastName: "",
          deliveryCity: "",
          deliveryLocation: "",
          price: "",
        });
        setDeliveryDate(null);
        setPhoneNumber("");
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log(err);
      toast.error("Une erreur s'est produite");
    } finally {
      setPending(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
      <div className="fixed flex items-center justify-center w-full h-[96%] ">
        <div className="bg-white w-[98%] sm:w-1/2 lg:w-1/3 max-h-full rounded-lg shadow-lg overflow-y-scroll">
          <div className="p-4 mb-12 md:mb-0">
            <p className="mb-4 text-center text-gray-700">
              Le livre est à{" "}
              <span className="text-xl font-bold lg:text-3xl">
                {formatPrice(10000)}
              </span>
            </p>
            <div className="flex items-center justify-between">
              <h2 className="mb-4 text-xl font-bold text-black">
                Remplissez le formulaire
              </h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <X />
              </button>
            </div>
            <form onSubmit={handleCommande}>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1 text-gray-700">
                  Prénom
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="w-full px-2 py-3 text-gray-700 border border-gray-300 rounded"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="name" className="block mb-1 text-gray-700">
                  Nom
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="w-full px-2 py-3 text-gray-700 border border-gray-300 rounded"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="flex items-center mb-1 text-gray-700"
                >
                  <svg
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 mr-2"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 31C23.732 31 30 24.732 30 17C30 9.26801 23.732 3 16 3C8.26801 3 2 9.26801 2 17C2 19.5109 2.661 21.8674 3.81847 23.905L2 31L9.31486 29.3038C11.3014 30.3854 13.5789 31 16 31ZM16 28.8462C22.5425 28.8462 27.8462 23.5425 27.8462 17C27.8462 10.4576 22.5425 5.15385 16 5.15385C9.45755 5.15385 4.15385 10.4576 4.15385 17C4.15385 19.5261 4.9445 21.8675 6.29184 23.7902L5.23077 27.7692L9.27993 26.7569C11.1894 28.0746 13.5046 28.8462 16 28.8462Z"
                        fill="#BFC8D0"
                      ></path>{" "}
                      <path
                        d="M28 16C28 22.6274 22.6274 28 16 28C13.4722 28 11.1269 27.2184 9.19266 25.8837L5.09091 26.9091L6.16576 22.8784C4.80092 20.9307 4 18.5589 4 16C4 9.37258 9.37258 4 16 4C22.6274 4 28 9.37258 28 16Z"
                        fill="url(#paint0_linear_87_7264)"
                      ></path>{" "}
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.5109 2.661 20.8674 3.81847 22.905L2 30L9.31486 28.3038C11.3014 29.3854 13.5789 30 16 30ZM16 27.8462C22.5425 27.8462 27.8462 22.5425 27.8462 16C27.8462 9.45755 22.5425 4.15385 16 4.15385C9.45755 4.15385 4.15385 9.45755 4.15385 16C4.15385 18.5261 4.9445 20.8675 6.29184 22.7902L5.23077 26.7692L9.27993 25.7569C11.1894 27.0746 13.5046 27.8462 16 27.8462Z"
                        fill="white"
                      ></path>{" "}
                      <path
                        d="M12.5 9.49989C12.1672 8.83131 11.6565 8.8905 11.1407 8.8905C10.2188 8.8905 8.78125 9.99478 8.78125 12.05C8.78125 13.7343 9.52345 15.578 12.0244 18.3361C14.438 20.9979 17.6094 22.3748 20.2422 22.3279C22.875 22.2811 23.4167 20.0154 23.4167 19.2503C23.4167 18.9112 23.2062 18.742 23.0613 18.696C22.1641 18.2654 20.5093 17.4631 20.1328 17.3124C19.7563 17.1617 19.5597 17.3656 19.4375 17.4765C19.0961 17.8018 18.4193 18.7608 18.1875 18.9765C17.9558 19.1922 17.6103 19.083 17.4665 19.0015C16.9374 18.7892 15.5029 18.1511 14.3595 17.0426C12.9453 15.6718 12.8623 15.2001 12.5959 14.7803C12.3828 14.4444 12.5392 14.2384 12.6172 14.1483C12.9219 13.7968 13.3426 13.254 13.5313 12.9843C13.7199 12.7145 13.5702 12.305 13.4803 12.05C13.0938 10.953 12.7663 10.0347 12.5 9.49989Z"
                        fill="white"
                      ></path>{" "}
                      <defs>
                        {" "}
                        <linearGradient
                          id="paint0_linear_87_7264"
                          x1="26.5"
                          y1="7"
                          x2="4"
                          y2="28"
                          gradientUnits="userSpaceOnUse"
                        >
                          {" "}
                          <stop stop-color="#5BD066"></stop>{" "}
                          <stop offset="1" stop-color="#27B43E"></stop>{" "}
                        </linearGradient>{" "}
                      </defs>{" "}
                    </g>
                  </svg>{" "}
                  Numéro de téléphone(Whatsapp)
                </label>

                <PhoneInput
                  label="Numéro de téléphone"
                  localization={_fr}
                  country={"ci"}
                  value={phoneNumber}
                  name="phoneNumber"
                  onChange={(phone) => setPhoneNumber(phone)}
                  //onlyCountries={["ci", "bf"]}
                  enableSearch
                  enableLongNumbers
                  inputProps={{
                    name: "phone",
                    className:
                      "border border-gray-300 pl-12 px-2 py-3 w-full rounded text-gray-700",
                  }}
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="deliveryCity"
                  className="block mb-1 text-gray-700"
                >
                  Ville de livraison
                </label>
                <select
                  name="deliveryCity"
                  id="deliveryCity"
                  value={formData.deliveryCity}
                  onChange={handleChange}
                  className="w-full px-2 py-3 text-gray-700 border border-gray-300 rounded"
                >
                  <option value="">Selectionnez la ville de livraison</option>
                  {cityData.map((city) => (
                    <option key={city.id} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </select>
                {formData.deliveryCity ? (
                  formData.deliveryCity === "Autre pays" ? (
                    <p className="text-gray-600">
                      Le livre coûte{" "}
                      {cityData.find((city) => city.name === "Autre pays")
                        ?.price || "N/A"}{" "}
                      FCFA, la livraison à vos frais
                    </p>
                  ) : (
                    <p className="text-gray-600">
                      {cityData.find(
                        (city) => city.name === formData.deliveryCity
                      )?.price || "N/A"}{" "}
                      FCFA
                    </p>
                  )
                ) : null}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="deliveryLocation"
                  className="block mb-1 text-gray-700"
                >
                  Lieu de livraison
                </label>
                <input
                  type="text"
                  id="deliveryLocation"
                  name="deliveryLocation"
                  value={formData.deliveryLocation}
                  placeholder="Exemple: Cocody Faya"
                  onChange={handleChange}
                  className="w-full px-2 py-3 text-gray-700 border border-gray-300 rounded"
                />
              </div>
              <div className="w-full mb-4">
                <label
                  htmlFor="deliveryDate"
                  className="block mb-1 font-bold text-gray-700"
                >
                  Choisissez votre jour de livraison
                </label>
                <div className="flex flex-col items-center w-full gap-2">
                  <DateSelector
                    selected={deliveryDate}
                    onChange={setDeliveryDate}
                  />
                  {deliveryDate && (
                    <p className="text-gray-600">{formatDate(deliveryDate)}</p>
                  )}
                </div>
                {/* <small className="text-sm text-red-400">
                  Stock très limité(date en fonction du stock)
                </small> */}
              </div>
              <div className="mb-4">
                {formData.deliveryCity === "Autre pays" ? (
                  <p className="text-[var(--primary-color)] text-sm  font-bold">
                    Livraison à vos frais
                  </p>
                ) : (
                  <div>
                    <p className="text-sm text-gray-700">
                      Expédition à partir de{" "}
                      <span className="text-[var(--primary-color)] font-bold">
                        2500 FCFA
                      </span>
                    </p>
                    <p className="text-sm text-gray-700">
                      Livraison à partir de{" "}
                      <span className="text-[var(--primary-color)] font-bold">
                        1500 FCFA
                      </span>{" "}
                      (payez à la livraison)
                    </p>
                  </div>
                )}
              </div>

              {pending ? (
                <div className="flex items-center justify-end w-full">
                  <Spinner className="text-primary" />
                </div>
              ) : (
                <div className="flex items-center justify-end w-full">
                  <input
                    type="submit"
                    value={
                      formData.deliveryCity === "Hors d'Abidjan"
                        ? "Payer maintenant"
                        : "Commander"
                    }
                    className="self-start p-2 mt-2 font-bold text-white cursor-pointer bg-primary "
                    disabled={pending}
                  />
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
