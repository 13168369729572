import React from "react";
import ReactPlayer from "react-player/lazy";
import Container from "./Container";
import Title from "./Title";
export const formatPrice = (price) => {
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XOF",
  })
    .format(price)
    .replace("XOF", "C FCFA");
};

const Info = () => {
  return (
    <Container>
      <Title title="Que contient ce livre ?" />
      <div className="flex flex-col items-center justify-center gap-4 mt-4">
        <div className="flex items-center w-full p-2 bg-white rounded aspect-video md:w-1/2">
          <ReactPlayer
            url="https://youtu.be/fzP7UMJwYCM"
            width="100%"
            height={"100%"}
            controls
          />
        </div>
      </div>
    </Container>
  );
};

export default Info;
