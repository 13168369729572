import React from "react";
import Container from "./Container";
import Title from "./Title";

const External = () => {
  return (
    <div id="autre">
      <Container>
        <Title title="Autres livres" />
        <div className="flex flex-wrap items-center justify-center gap-4 mt-8">
          <div className="w-64 overflow-hidden rounded-lg h-80">
            <a href="https://livre.sc-digital.org">
              <img
                src={require("../assets/images/facebook.webp")}
                alt="facebook"
                className="object-cover h-full"
              />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default External;
