import React from "react";
import check from "../assets/images/check.png";
import cover3 from "../assets/images/cover3.jpeg";
import Buy from "./Buy";
import Container from "./Container";
import Title from "./Title";
const Objectif = () => {
  return (
    <div id="objectif">
      <Container>
        <Title title="OBJECTIF" />
        <div className="container grid items-start justify-center grid-cols-1 gap-4 px-4 py-16 md:grid-cols-2">
          <div className="text-white">
            <p className="mb-6 text-[20px] text-secondary font-bold ">
              Grâce aux conseils détaillés dans ce guide, vous transformerez
              WhatsApp Business en une véritable machine à générer des prospects
              et des ventes !
            </p>

            <p className="mb-4 text-[20px] font-semibold">
              Vous y découvrirez notamment :
            </p>
            <ul className="flex flex-col gap-3 pl-3 mb-6">
              <li className="flex items-center">
                <img
                  src={check}
                  alt="check-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                  loading="lazy"
                />
                Les étapes pour créer un profil professionnel magnetique
              </li>
              <li className="flex items-center">
                <img
                  src={check}
                  alt="check-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                  loading="lazy"
                />
                Mes techniques infaillibles pour générer des leads sur WhatsApp
              </li>
              <li className="flex items-center">
                <img
                  src={check}
                  alt="check-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                  loading="lazy"
                />
                Comment fidéliser vos clients et les faire revenir grâce au SAV
                sur WhatsApp
              </li>
              <li className="flex items-center">
                <img
                  src={check}
                  alt="check-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                  loading="lazy"
                />
                Mon template de funnel de vente étape par étape pour vendre
                n'importe quel produit
              </li>
              <li className="flex items-center">
                <img
                  src={check}
                  alt="check-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                  loading="lazy"
                />
                Les outils indispensables pour automatiser et booster vos
                résultats
              </li>
              <li className="flex items-center text-[20px] font-semibold">
                Et bien plus encore !
              </li>
            </ul>

            <p className="mb-6 text-[20px]">
              Si vous êtes prêt à exploiter tout le potentiel de WhatsApp
              Business pour votre business, commandez dès maintenant ce guide
              complet !
            </p>
            <Buy />
          </div>
          <img
            src={cover3}
            alt="check-icon"
            className="mx-auto rounded-lg w-300px md:w-400px"
            loading="lazy"
          />
        </div>
      </Container>
    </div>
  );
};

export default Objectif;
