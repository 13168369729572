import Container from "./Container";
import Title from "./Title";

const Contact = () => {
  return (
    <div id="contact">
      <Container>
        <Title title={"CONTACT"} />
        <div className="flex flex-col justify-center w-full gap-4 px-6 my-4 md:flex-row lg:px-8">
          <div className="flex flex-col justify-center gap-4 p-4 bg-white rounded-lg">
            <p className="text-[20px]">
              Addresse:{" "}
              <span className="font-semibold">
                Cocody Faya, après le supermarché Ivoire Center
              </span>
            </p>
            <p className="text-[20px]">
              Téléphone:{" "}
              <span className="font-semibold">
                {" "}
                <a href="tel:+2250748156408">+2250748156408</a>
              </span>
            </p>
            <p className="text-[20px]">
              Email:{" "}
              <span className="font-semibold">
                <a href="mailto:livre@sc-digital.org?subject=Bésoin d'assistance&body=Nom Complet: %0D%0A%0D%0ANuméro de téléphone:%0D%0A%0D%0AMessage:">
                  livre@sc-digital.org
                </a>
              </span>
            </p>
          </div>
        </div>
        <div className="flex items-center justify-center w-full mb-6 max-w-7xl">
          <iframe
            title="Google Maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3972.3221318685382!2d-3.94033172533979!3d5.367741935514762!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xfc1edeca903dddf%3A0xea43171da2cdfd95!2sSC%20DIGIT%40L!5e0!3m2!1sfr!2sci!4v1706907006166!5m2!1sfr!2sci"
            width="600"
            height="450"
            style={{ border: 0, borderRadius: "10px" }}
            allowFullscreen
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </Container>
    </div>
  );
};

export default Contact;
