import React, { useState } from "react";
import { X } from "react-feather";
import { FaGooglePlay } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import FormModal from "./FormModal";
import { formatPrice } from "./Info";
const Buy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setShowFormModal(false);
  };

  const handleVersionPhysique = () => {
    setShowFormModal(true);
    setIsOpen(false);
  };

  const navigate = useNavigate();
  return (
    <div>
      <button
        onClick={() => navigate("/commande")}
        className="relative flex items-center justify-center px-8 py-2 m-2 text-lg font-normal text-white rounded-md w-max bg-third btn-animation"
      >
        Acheter le livre maintenant
      </button>

      {isOpen && (
        <div className="fixed inset-0 top-0 z-50 flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50"></div>
          <div className="fixed flex items-center justify-center w-full h-full">
            <div className="bg-white w-[90%] md:w-1/2 lg:w-1/3 rounded-lg shadow-lg">
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <h2 className="flex items-center justify-center mb-4 text-xl font-bold text-black">
                    Choisir son support du livre
                  </h2>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={closeModal}
                  >
                    <X />
                  </button>
                </div>
                <p className="mb-4 text-center text-gray-700">
                  Le livre est à{" "}
                  <span className="text-xl font-bold lg:text-3xl">
                    {formatPrice(10000)}
                  </span>
                  .
                  <br />
                  <span>{formatPrice(5000)} pour le support numérique</span>
                </p>
                <p className="mb-4 text-center text-gray-700">
                  Cliquez sur le support que vous souhaitez obtenir:
                </p>
                <div className="flex flex-col items-center justify-between gap-3">
                  <button
                    className="bg-[var(--primary-color)] text-white px-4 py-3 rounded-lg w-full"
                    onClick={handleVersionPhysique}
                  >
                    Je veux la version physique
                  </button>
                  <small></small>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.scdigital.vendrefacilement2"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center justify-center bg-[var(--primary-color)] text-white px-4 py-2 rounded-lg w-full"
                  >
                    <FaGooglePlay className="mr-2" />
                    Je veux la version numérique
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFormModal && <FormModal closeModal={closeModal} />}
    </div>
  );
};

export default Buy;
