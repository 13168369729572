import React from "react";

const Container = ({ children }) => {
  return (
    <div className="flex flex-col w-full px-4 py-6 mx-auto max-w-7xl border-b-[1px] border-white">
      {children}
    </div>
  );
};

export default Container;
