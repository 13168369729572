import React from "react";
import { comments } from "../data";
import Container from "./Container";
import Title from "./Title";

const Comment = () => {
  const [showMore, setShowMore] = React.useState(null);

  const handleShowMore = (index) => {
    setShowMore((prevShowMore) => (prevShowMore === index ? null : index));
  };

  return (
    <Container>
      <Title title="COMMENTAIRES" />
      <div className="container grid items-center justify-center grid-cols-1 gap-4 px-4 py-16 sm:grid-cols-2 md:grid-cols-3">
        {comments.map((comment) => (
          <div
            className="bg-white p-2 shadow shadow-black/20 rounded-lg min-h-[160px] flex flex-col justify-between"
            key={comment.id}
          >
            <h2 className="text-[18px] font-bold">{comment.name}</h2>
            <p className="text-sm">
              {showMore === comment.id
                ? comment.message
                : comment.message.slice(0, 100).concat("...")}
            </p>
            {comment.message.length > 100 && (
              <p
                className="text-sm cursor-pointer text-primary"
                onClick={() => handleShowMore(comment.id)}
              >
                {showMore === comment.id ? "Cacher" : "Voir plus"}
              </p>
            )}
            <p className="text-end">
              <small className="text-muted">{comment.date}</small>
            </p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Comment;
