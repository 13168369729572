export const faqs = [
  {
    id: 0,
    question:
      "Quels types de produits ou services puis-je vendre avec les stratégies de vente sur WhatsApp Business que vous proposez ?",
    answer:
      "Mes stratégies sont adaptées à tous les domaines d'activité ! Que vous vendiez des produits physiques, des services en ligne, des formations, des consultations, ou tout autre chose, ma technique peut être appliquée avec succès pour booster vos ventes sur Facebook.",
  },
  {
    id: 1,
    question:
      "Dois-je avoir des connaissances techniques avancées pour utiliser vos stratégies de vente sur WhatsApp Business ?",
    answer:
      "Absolument pas ! Mon livre a été conçu pour être accessible à tous, quel que soit votre niveau de compétence technique. Mes stratégies sont claires, simples à suivre et accompagnées d'exemples pratiques pour vous guider pas à pas vers le succès.",
  },
  {
    id: 2,
    question:
      "Comment puis-je être sûr(e) que vos stratégies fonctionneront pour mon entreprise ou business ?",
    answer:
      "Mes stratégies ont été testées et éprouvées avec succès par de nombreux entrepreneurs dans divers secteurs d'activité.",
  },
  {
    id: 3,
    question:
      "Quel est le temps nécessaire pour commencer à voir des résultats avec vos stratégies de vente sur Facebook ?",
    answer:
      "Les résultats peuvent varier en fonction de divers facteurs tels que votre niche, votre offre et votre niveau d'engagement. Cependant, de nombreux lecteurs ont constaté des améliorations significatives en quelques semaines seulement.",
  },
  {
    id: 4,
    question: "Comment puis-je rejoindre le groupe d'accompagnement WhatsApp ?",
    answer:
      "Vous recevrez des instructions détaillées sur la façon de rejoindre notre groupe d'accompagnement WhatsApp exclusif. Vous pourrez ainsi interagir avec d'autres participants, partager vos expériences, poser vos questions et bénéficier de conseils personnalisés de notre équipe et des membres expérimentés.",
  },
];

export const comments = [
  {
    id: 1,
    name: "AminaSow",
    message:
      "Ce guide a été un véritable atout pour mon entreprise sur WhatsApp ! Les stratégies sont pratiques et faciles à mettre en œuvre. Merci beaucoup pour cette ressource précieuse !",
    date: "01-01-2024",
  },
  {
    id: 2,
    name: "KwameSeller",
    message:
      "En tant que petit entrepreneur, j'ai trouvé les idées dans ce livre extrêmement utiles. Les conseils sur la communication efficace et la construction de relations client ont considérablement augmenté mes ventes. Hautement recommandé !",
    date: "10-01-2024",
  },
  {
    id: 3,
    name: "NiaCommerce",
    message:
      "Le Guide Complet est une lecture indispensable pour toute personne utilisant WhatsApp Business. L'approche étape par étape et les exemples pratiques facilitent la compréhension et l'application. Mes ventes ont nettement augmenté depuis la mise en œuvre de ces stratégies !",
    date: "02-02-2024",
  },
];

export const cityData = [
  {
    id: 1,
    name: "Abidjan",
    price: 11500,
  },
  {
    id: 2,
    name: "Bassam",
    price: 12000,
  },
  {
    id: 3,
    name: "Port bouet",
    price: 12000,
  },
  {
    id: 4,
    name: "Anyama",
    price: 12000,
  },
  {
    id: 5,
    name: "Aéroport",
    price: 12000,
  },
  {
    id: 6,
    name: "Hors d'Abidjan",
    price: 12500,
  },
  {
    id: 7,
    name: "Autre pays",
    price: 11500,
  },
];

export const africanNames = [
  {
    firstName: "Kouamé",
    lastName: "Ange",
    city: "Abidjan",
  },
  {
    firstName: "Ama",
    lastName: "Boateng",
    city: "Abidjan",
  },
  {
    firstName: "Jean",
    lastName: "Pascal",
    city: "Bassam",
  },
  {
    firstName: "Akissi",
    lastName: "Ange",
    city: "Port bouet",
  },
  {
    firstName: "Pierre",
    lastName: "Zadi",
    city: "Bassam",
  },
  {
    firstName: "Afia",
    lastName: "Mardo",
    city: "Port bouet",
  },
  {
    firstName: "Pascal",
    lastName: "Koffi",
    city: "Bassam",
  },
  {
    firstName: "Akua",
    lastName: "Addo",
    city: "Aéroport",
  },
  {
    firstName: "Philippe",
    lastName: "Dalli",
    city: "Abidjan",
  },
  {
    firstName: "Adwoa",
    lastName: "Adomako",
    city: "Abidjan",
  },
  {
    firstName: "Henry",
    lastName: "Kouamé",
    city: "Anyama",
  },
  {
    firstName: "Abena",
    lastName: "Mensah",
    city: "Abidjan",
  },
  {
    firstName: "Sekou",
    lastName: "Touré",
    city: "Abidjan",
  },
  {
    firstName: "Aissatou",
    lastName: "Traoré",
    city: "Anyama",
  },
  {
    firstName: "Salim",
    lastName: "Diop",
    city: "Aéroport",
  },
  {
    firstName: "Kadiatou",
    lastName: "Ndiaye",
    city: "Abidjan",
  },
  {
    firstName: "Amadou",
    lastName: "Camara",
    city: "Abidjan",
  },
  {
    firstName: "Mariam",
    lastName: "Toure",
    city: "Abidjan",
  },
  {
    firstName: "Idrissa",
    lastName: "Barry",
    city: "Abidjan",
  },
  {
    firstName: "Aminata",
    lastName: "Diallo",
    city: "Abidjan",
  },
  {
    firstName: "Lassana",
    lastName: "Sylla",
    city: "Abidjan",
  },
  {
    firstName: "Fatoumata",
    lastName: "Bah",
    city: "Abidjan",
  },
  {
    firstName: "Issa",
    lastName: "Ouedraogo",
    city: "Hors d'Abidjan",
  },
  {
    firstName: "Kadija",
    lastName: "Sanogo",
    city: "Hors d'Abidjan",
  },
  {
    firstName: "Souleymane",
    lastName: "Cissé",
    city: "Hors d'Abidjan",
  },
  {
    firstName: "Awa",
    lastName: "Diallo",
    city: "Bassam",
  },
  {
    firstName: "Modibo",
    lastName: "Diarra",
    city: "Port bouet",
  },
  {
    firstName: "Fanta",
    lastName: "Kouyaté",
    city: "Anyama",
  },
  {
    firstName: "Mamadou",
    lastName: "Traoré",
    city: "Abidjan",
  },
  {
    firstName: "Hawa",
    lastName: "Barry",
    city: "Abidjan",
  },
  {
    firstName: "Djibril",
    lastName: "Ouattara",
    city: "Abidjan",
  },
  {
    firstName: "Sira",
    lastName: "Cissé",
    city: "Abidjan",
  },
  {
    firstName: "Ibrahim",
    lastName: "Dabo",
    city: "Port bouet",
  },
  {
    firstName: "Nene",
    lastName: "Addo",
    city: "Abidjan",
  },
  {
    firstName: "Aissatou",
    lastName: "Fall",
    city: "Dakar",
  },
  {
    firstName: "Mamadou",
    lastName: "Ndiaye",
    city: "Dakar",
  },
  {
    firstName: "Fatou",
    lastName: "Sow",
    city: "Dakar",
  },
  {
    firstName: "Ousmane",
    lastName: "Ba",
    city: "Dakar",
  },
  {
    firstName: "Aminata",
    lastName: "Diallo",
    city: "Thiès",
  },
  {
    firstName: "Cheikh",
    lastName: "Dramé",
    city: "Thiès",
  },
  {
    firstName: "Mariama",
    lastName: "Diop",
    city: "Thiès",
  },
  {
    firstName: "Ibrahima",
    lastName: "Sow",
    city: "Dakar",
  },
  {
    firstName: "Awa",
    lastName: "Gueye",
    city: "Dakar",
  },
  {
    firstName: "Moussa",
    lastName: "Diagne",
    city: "Dakar",
  },
];
