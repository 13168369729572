import React from "react";
import Buy from "./Buy";

const Nav = () => {
  return (
    <div className="sticky top-0 z-50 flex items-center justify-center w-full h-16 px-4 bg-secondary">
      <ul className="flex items-center gap-6 text-sm font-bold">
        <a href="/">
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Accueil
          </li>
        </a>
        <a href="#faq">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            FAQ
          </li>
        </a>
        <a href="#avis">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Témoignage
          </li>
        </a>
        <a href="#contact">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Contact
          </li>
        </a>
        <a href="#autre">
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Autre livre
          </li>
        </a>
        <li className="absolute right-0 hidden md:block">
          <Buy />
        </li>
      </ul>
    </div>
  );
};

export default Nav;
