import React from "react";
import idea from "../assets/images/idea.png";
import Buy from "./Buy";
import Container from "./Container";
import Title from "./Title";

const Desc = () => {
  return (
    <div id="desc">
      <Container>
        <Title title="AUTEUR" />
        <div className="container grid items-start justify-center grid-cols-1 gap-4 px-4 py-16 md:grid-cols-2">
          <img
            src={require("../assets/images/3.png")}
            alt="sekou"
            className="mx-auto rounded-lg w-300px md:w-400px"
            loading="lazy"
          />
          <div className="text-white">
            <p className="mb-6 text-[20px] text-secondary font-bold ">
              WHATSAPP BUSINESS, L'OUTIL ULTIME POUR DÉVELOPPER VOS VENTES!
            </p>
            <p className="mb-6 text-sm md:text-lg ">
              Avec ses 2 milliards d'utilisateurs actifs, WhatsApp Business est
              devenu incontournable pour interagir avec vos clients et
              prospects. Mais comment exploiter tout le potentiel commercial de
              cette plateforme ?
            </p>
            <p className="mb-4 text-sm md:text-lg">
              Dans ce guide pratique, découvrez toutes les astuces et stratégies
              pour :
            </p>
            <ul className="flex flex-col gap-3 pl-3 mb-6">
              <li className="flex items-center">
                <img
                  src={idea}
                  alt="idea-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                />
                Configurer un profil professionnel percutant
              </li>
              <li className="flex items-center">
                <img
                  src={idea}
                  alt="idea-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                />
                Constituer une audience ciblée et engagée
              </li>
              <li className="flex items-center">
                <img
                  src={idea}
                  alt="idea-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                />
                Maximiser votre visibilité avec les statuts
              </li>
              <li className="flex items-center">
                <img
                  src={idea}
                  alt="idea-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                />
                Mettre en place un tunnel de vente efficace
              </li>
              <li className="flex items-center">
                <img
                  src={idea}
                  alt="idea-icon"
                  className="mr-2 text-green-500 w-[20px] md:w-[25px] aspect-square"
                />
                Automatiser et optimiser vos campagnes avec des outils dédiés
              </li>
            </ul>
            <p className="mb-2 text-[18px]">
              Grâce aux conseils de l'expert Sekou KONATE, qui a généré plus de
              1 million de FCFA de chiffre d'affaire en 24h sur WhatsApp, vous
              allez enfin pouvoir transformer cette messagerie en machine à
              business!
            </p>
            <p className="mb-6 text-[18px]">
              Alors n'attendez plus et maîtrisez dès maintenant la vente sur
              WhatsApp Business pour booster vos résultats !
            </p>
            <Buy />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Desc;
