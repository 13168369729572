import React from "react";
import gift from "../assets/images/bonus.png";
import Container from "./Container";
const Bonus = () => {
  return (
    <Container>
      <div className="flex items-center justify-between w-full max-w-3xl p-4 m-4 rounded-md bg-secondary">
        <img src={gift} alt="gift-icon" className="w-[80px] md:w-[120px]" />
        <div className="flex flex-col gap-4 p-4">
          <h2 className="text-2xl font-bold border-b-2 border-gray-800 w-max">
            BONUS
          </h2>
          <p className="text-sm md:text-lg">
            En achetant dès maintenant, vous recevrez en cadeau mon méga pack de
            vidéos de formation pour vous faciliter la mise en application.
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Bonus;
