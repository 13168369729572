import React from "react";

const Title = ({ title }) => {
  return (
    <div className="self-center px-16 py-4 text-xl font-bold text-center bg-white rounded-30px rounded-3xl w-max shadow-[0px_10px_1px_rgb(26_185_102),_0_10px_20px_rgba(20,_20,_20,_0.3)]">
      {title}
    </div>
  );
};

export default Title;
