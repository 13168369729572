import React from "react";
import Buy from "./Buy";
const cover1 = require("../assets/images/cover1.png");
const cover2 = require("../assets/images/cover2.png");
const cover = require("../assets/images/cover.png");
const arrowdown = require("../assets/images/arrowdown.png");

const Hero = () => {
  return (
    <section className="relative bg-transparent  h-[calc(100dvh-64px)] flex flex-col items-center justify-center ">
      <div className="flex absolute w-full h-full z-[-2]">
        <div className="absolute backdrop-brightness-[0.6] w-full h-full"></div>
        <div
          style={{
            backgroundImage: `url(${cover})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          className="absolute w-full h-full"
        />
      </div>
      <div className="flex items-center justify-between p-4 absolute w-full h-full z-[-1] overflow-hidden">
        <img src={cover1} alt="cover1" className="h-full" />
        <img src={cover2} alt="cover2" className="hidden h-full md:block" />
      </div>

      <div className="flex flex-col items-center px-4 py-16 mx-auto text-center rounded-lg bg-white/40 backdrop-blur-sm xl:max-w-4xl">
        <h1 className="w-full p-5 text-lg leading-none text-gray-800 rounded-md md:text-xl">
          Prêt à transformer votre approche commerciale et à révolutionner vos
          résultats ? Laissez{" "}
          <span className="font-bold">
            LE GUIDE COMPLET POUR LA VENTE SUR WHATSAPP BUSINESS
          </span>{" "}
          être votre guide vers le succès commercial.
        </h1>
        <img
          src={arrowdown}
          alt="arrow-icon"
          className="w-8 mt-4 mb-2 md:w-10"
        />
        <Buy />
      </div>
    </section>
  );
};

export default Hero;
