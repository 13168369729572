import React, { useState } from "react";
import c0 from "../assets/images/c0.jpg";
import c1 from "../assets/images/c1.jpg";
import c10 from "../assets/images/c10.jpg";
import c11 from "../assets/images/c11.jpg";
import c13 from "../assets/images/c13.jpg";
import c2 from "../assets/images/c2.jpg";
import c3 from "../assets/images/c3.jpg";
import c4 from "../assets/images/c4.jpg";
import c5 from "../assets/images/c5.jpg";
import c6 from "../assets/images/c6.jpg";
import c7 from "../assets/images/c7.jpg";
import c8 from "../assets/images/c8.jpg";
import c9 from "../assets/images/c9.jpg";
import h1 from "../assets/images/h1.jpg";
import Container from "./Container";
import CustomCarousel from "./CustomCarousel";
import Title from "./Title";

const Avis = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const imagesC = [c0, c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c13];
  const imagesH = [h1];

  return (
    <div id="avis" className="pb-8">
      <Container>
        <Title title="TEMOIGNAGE" />
        <div className="flex flex-wrap items-center justify-center grid-cols-1 gap-4 py-4 mx-auto md:grid-cols-2 lg:grid-cols-3">
          {/* Image Slider */}
          {/* <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto border">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                <div className=" h-[350px]  flex items-center justify-center">
                  <ReactPlayer
                    url="https://youtu.be/E9qkjFeJE8E"
                    width="100%"
                    height={"100%"}
                    controls
                  />
                </div>
                <div className=" h-[350px]  flex items-center justify-center">
                  <ReactPlayer
                    url="https://youtube.com/shorts/zmKGbU7RYTI?feature=share"
                    width="100%"
                    height={"100%"}
                    controls
                  />
                </div>
              </CustomCarousel>
            </div>
          </div> */}
          {/* Image Slider */}
          <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                {imagesH.map((image, index) => (
                  <div
                    key={index}
                    className="inline-block h-[350px]"
                    onClick={() => openModal(image)}
                  >
                    <img src={image} alt="slide" className="" loading="lazy" />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </div>
          {/* Image Slider */}
          <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                {imagesC.map((image, index) => (
                  <div
                    key={index}
                    className="inline-block h-[350px]"
                    onClick={() => openModal(image)}
                  >
                    <img src={image} alt="slide" className="" loading="lazy" />
                  </div>
                ))}
              </CustomCarousel>
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black bg-opacity-50"
            style={{ zIndex: 9999 }}
          >
            <div className="relative w-full h-full max-h-full p-4 mx-auto bg-white">
              <button
                onClick={closeModal}
                className="absolute z-10 px-4 py-2 mt-4 text-white bg-red-300 rounded-lg right-2 top-2"
              >
                Fermer
              </button>
              <img
                src={selectedImage}
                alt="preview"
                className="absolute max-h-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
              />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Avis;
